/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Select, InputNumber, Form, Drawer, Button } from 'antd'
import * as actions from '../actions'

const AddProcessor = ({
  ruleId,
  processors,
  ruleProcessors,
  setRuleProcessorsList,
  addRuleProcessor,
}) => {
  const [form] = Form.useForm()
  const [isVisible, setVisible] = useState(false)

  const onFinishHendler = async (values) => {
    await addRuleProcessor({
      ruleId,
      exProcessorId: values.externalId,
      percent: values.percent,
    })
    setRuleProcessorsList({ page: 1, size: 100, filters: { ruleId: ruleId } })
    form.resetFields()
    setVisible(false)
  }

  return (
    <div>
      <Button
        type="primary"
        className="add-new-site_button"
        onClick={() => setVisible(true)}
      >
        Add a Processor
      </Button>

      <Drawer
        title="Add Processor"
        width="400"
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinishHendler}
          form={form}
        >
          <Form.Item
            name="externalId"
            label="processors"
            fieldKey="externalId"
            rules={[
              {
                required: true,
                message: 'Please select processor!',
              },
            ]}
            className="select-form_item"
          >
            <Select placeholder="Please select processors!">
              {processors.map((proc, key) => {
                return (
                  <Select.Option key={key} value={proc.externalId}>
                    {proc.externalId} - {proc.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="percent"
            fieldKey="percent"
            rules={[
              {
                required: true,
                message: 'max 100%',
                min: 1,
                max: 100,
                type: 'number',
              },
            ]}
            label="Percentage"
            className="ask_modal-item__small"
          >
            <InputNumber />
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button
              type="primary"
              htmlType="submit"
              className="add-new-site_button"
            >
              Add
            </Button>
          </div>
        </Form>
      </Drawer>
    </div>
  )
}

const mapStateToProps = (store) => ({
  processors: store.processors.list,
  ruleProcessors: store.rules.ruleProcessorslist,
})

const mapDispatchToPtops = {
  addRuleProcessor: actions.addRuleProcessor,
  deleteRuleProduct: actions.deleteRuleProduct,
  setRuleProcessorsList: actions.setRuleProcessorsList,
}

export default connect(mapStateToProps, mapDispatchToPtops)(AddProcessor)
