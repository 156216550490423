import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Form, Select } from 'antd'
import * as actions from '../actions'
import * as companyActions from '../../Companies/actions'

const CreateSite = ({
  campaigns,
  fetchCompaniesList,
  createSite,
  callback,
}) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    fetchCompaniesList({ page: 1, size: 100 })
  }, [])

  const onFinishHandler = async (values) => {
    await createSite({ campaignId: values.campaignId, action: 'download' })
    callback()
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <Button
        type="primary"
        className="add-new-site_button"
        onClick={() => setVisible(true)}
      >
        Import Sites
      </Button>
      <Drawer
        title="Import Sites for"
        width="400"
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
        >
          <Form.Item
            name="campaignId"
            label="Select Company"
            rules={[{ required: true, message: 'Please select company!' }]}
          >
            <Select>
              {campaigns.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button
              type="primary"
              htmlType="submit"
              className="add-new-site_button"
            >
              Import
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapStateToProps = (store) => ({
  campaigns: store.companies.list,
})

const mapDispatchToProps = {
  createSite: actions.createSite,
  fetchCompaniesList: companyActions.fetchCompaniesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSite)
