import axios from '../../utils/axios'
import settings from '../../settings'
import { types } from './types'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import {
  errorNotification,
  successNotification,
} from '../../utils/notificationHelper'

const REMOVE_SITE = `
  mutation RemoveSite($siteId: ID!) {
    removeSite(siteId: $siteId)
  }
`

const FETCH_SITES_LIST = `
query siteListPage($page: Int, $size: Int, $filters: SiteFilters!) {
  siteListPage(page: $page, size: $size, filters: $filters) {
    items {
      id
      externalId
      name
      campaignId
      url
    }
    total
  }
}
`

const CREATE_SITE = `
  mutation createSite ( $campaignId: String!, $action: String!) {
    createSite( campaignId: $campaignId, action: $action) {
      externalId
      name
      campaignId
      createdAt
      updatedAt
    }
  }
`

const UPDATE_SITE = `
  mutation updateSite($siteId: ID!, $name: String!, $url: String) {
    updateSite(siteId: $siteId, name: $name, url: $url)
  }
`

const SITES_FOR_OPTIONS = `
query siteForOptions {
  query {
    siteForOptions {
        id
        name 
    }
  }
}
`

//createSite
export const createSite = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: CREATE_SITE,
        variables: { ...formData },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { createSite } = data
    if (!createSite) {
      throw new Error("Can't Complete Operation!")
    }

    dispatch(successNotification('Operation Completed Successfully!'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//removeSite
export const removeSite = (siteId) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: REMOVE_SITE,
        variables: { siteId },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeSite } = data
    if (!removeSite) {
      throw new Error("Can't remove site")
    }
    dispatch({
      type: types.REMOVE_SITE,
      data: siteId,
    })

    dispatch(successNotification('Remove Site successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//fetchSitesList
export const fetchSitesList = ({ page, size, filters = {} }) => async (
  dispatch
) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: FETCH_SITES_LIST,
        variables: { page, size, filters },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { siteListPage } = data
    if (!siteListPage) {
      throw new Error("Can't get sites")
    }

    dispatch({
      type: types.SET_SITES_LIST,
      data: siteListPage,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//updateSite
export const updateSite = (args) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { siteId, name, url } = args

    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: UPDATE_SITE,
        variables: { siteId, name, url },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateSite } = data
    if (!updateSite) {
      throw new Error("Can't update site")
    }

    dispatch(successNotification('Site update successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//fetchSitesForOptions
export const fetchSitesForOptions = () => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: SITES_FOR_OPTIONS,
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { siteForOptions } = data
    if (!siteForOptions) {
      throw new Error("Can't get sites")
    }

    dispatch({
      type: types.SET_SITES_FOR_OPTIONS,
      data: siteForOptions,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}
