import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Input, Select, Form, Drawer } from 'antd'
import usaStates from '../../../usaStates'
import * as actions from '../actions'

const ClientInfo = ({
  record,
  updateClientInfo,
  pageNo,
  size,
  getClientsList,
  usersData,
}) => {
  const [form] = Form.useForm()
  const [isVisible, setVisible] = useState(false)

  const onFinishHandler = async (value) => {
    await updateClientInfo(value)
    getClientsList({ page: pageNo, size: size })
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <i
        className="zmdi zmdi-edit zmdi-hc-2x zmdi-hc-lg txt-warning users-list-action_item"
        title="Edit"
        type="primary"
        onClick={() => {
          setVisible(true)
        }}
      />

      <Drawer
        title="Client Info"
        width="600"
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
          className="addClient_form"
        >
          <Form.Item
            initialValue={record.email}
            className="addClient_form-item"
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Incorrect email', type: 'email' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.firstName}
            className="addClient_form-item"
            name="firstName"
            label="First Name"
            rules={[
              { required: true, message: 'Incorrect first name', min: 2 },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.lastName}
            className="addClient_form-item"
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Incorrect last name', min: 2 }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.companyName}
            className="addClient_form-item"
            name="companyName"
            label="Company Name"
            rules={[{ required: true, message: 'Incorrect company name' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.address1}
            className="addClient_form-item"
            name="address1"
            label="Address1"
            rules={[{ required: true, message: 'Incorrect address' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.address2}
            className="addClient_form-item"
            name="address2"
            label="Address2"
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.phone}
            className="addClient_form-item"
            name="phone"
            label="Phone Number"
            // eslint-disable-next-line no-useless-escape
            rules={[
              {
                required: true,
                message: 'Incorrect phone number',
                pattern: new RegExp(
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                  'im'
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.city}
            className="addClient_form-item"
            name="city"
            label="City"
            rules={[{ required: true, message: 'Incorrect city' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.state}
            className="addClient_form-item"
            name="state"
            label="State"
            rules={[{ required: true, message: 'Incorrect state' }]}
          >
            <Select>
              {usaStates.map((state) => (
                <Select.Option value={state} key={state}>
                  {state}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            initialValue={record.zip}
            className="addClient_form-item"
            name="zip"
            label="Zip Code"
            rules={[{ required: true, message: 'Incorrect zip code' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.id}
            className="addClient_form-item"
            name="id"
            label="Id"
          >
            <Input disabled />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}
const mapStateToProps = (store) => ({
  usersData: store.clients.list,
})

const mapDispatchToProps = {
  updateClientInfo: actions.updateClientInfo,
  getClientsList: actions.getClientsList,
  getClientList: actions.getClientList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientInfo)
