import React from 'react'

export const CustomerInfoItem = ({ name, info }) => {
  return (
    <div className="customer_main-info__item">
      <div className="customer_type__uppercase">{ name }</div>
      <div>{ info }</div>
    </div>
  )
}

export default CustomerInfoItem
