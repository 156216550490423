/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Select, InputNumber, Form, Drawer, Button } from 'antd'
import * as actions from '../actions'

const UpdateProcessor = ({
  processors,
  ruleProcessors,
  data,
  setRuleProcessorsList,
  updateRuleProcessor,
}) => {
  const [form] = Form.useForm()
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {})

  const onFinishHendler = async (values) => {
    await updateRuleProcessor({
      ruleId: data.ruleId,
      exProcessorId: values.exProcessorId,
      percent: values.percent,
    })
    await setRuleProcessorsList({
      page: 1,
      size: 100,
      filters: { ruleId: data.ruleId },
    })
    form.resetFields()
    setVisible(false)
  }

  return (
    <div>
      <i
        className="zmdi zmdi-edit zmdi-hc-lg zmdi-hc-2x txt-warning users-list-action_item"
        title="Edit"
        type="primary"
        onClick={() => {
          setVisible(true)
        }}
      />

      <Drawer
        title="Update Processor"
        width="400"
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinishHendler}
          form={form}
        >
          <Form.Item
            name="exProcessorId"
            fieldKey="exProcessorId"
            initialValue={data.exProcessorId}
            label="processors"
            rules={[
              {
                required: true,
                message: 'Please select processor!',
              },
            ]}
            className="select-form_item"
          >
            <Select placeholder="Please select processors!">
              {processors.map((proc) => {
                let num = ruleProcessors.find(
                  (el) => +el.exProcessorId === +proc.externalId
                )
                if (!num) {
                  return (
                    <Select.Option key={proc.id} value={proc.externalId}>
                      {proc.externalId} - {proc.name}
                    </Select.Option>
                  )
                }
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="percent"
            fieldKey="percent"
            initialValue={data.processorPercent}
            rules={[
              {
                required: true,
                message: 'max 100%',
                min: 1,
                max: 100,
                type: 'number',
              },
            ]}
            label="Percentage"
            className="ask_modal-item__small"
          >
            <InputNumber />
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button
              type="primary"
              htmlType="submit"
              className="add-new-site_button"
            >
              Update
            </Button>
          </div>
        </Form>
      </Drawer>
    </div>
  )
}

const mapStateToProps = (store) => ({
  processors: store.processors.list,
  ruleProcessors: store.rules.ruleProcessorslist,
})

const mapDispatchToPtops = {
  addRuleProduct: actions.addRuleProduct,
  updateRuleProcessor: actions.updateRuleProcessor,
  setRuleProductsList: actions.setRuleProductsList,
  setRuleProcessorsList: actions.setRuleProcessorsList,
}

export default connect(mapStateToProps, mapDispatchToPtops)(UpdateProcessor)
