import axios from '../../utils/axios'
import settings from '../../settings'
import { types } from './types'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import {
  errorNotification,
  successNotification,
} from '../../utils/notificationHelper'

const FETCH_CUSTOMERS_LIST = `
  query customersListPage($page: Int, $size: Int, $filters: CustomersFiltersType!, $taskId: Int) {
    customersListPage(page: $page, size: $size, filters: $filters, taskId: $taskId) {
      items {
        id
        externalId
        campaignId
        externalSiteId
      }
      total
    }
  }
`

const REMOVE_CUSTOMER = `
  mutation removeCustomer($externalId: ID!) {
    removeCustomer(externalId: $externalId)
  }
`

const CSV_CUSTOMERS = `
  mutation csvCustomers($externalId: ID!, $taskName: String!, $productId: ID!, $externalSiteId: ID!, $recurringId: ID!, $amount: String, $campaignId: ID!) {
    csvCustomers(externalId: $externalId, taskName: $taskName, externalSiteId: $externalSiteId, productId: $productId, recurringId: $recurringId, amount: $amount, campaignId: $campaignId)
  }
`

const DOWNLOAD_CUSTOMERS = `
  mutation downloadCustomers {
    downloadCustomers {
      items {
        id
      }
    }
  }
`

const GET_CUSTOMER = `
  query customer($customerId: ID!) {
    customer(customerId: $customerId) {
      id
      externalId
      externalSiteId
    }
  }
`

//sendSvgData
export const sendSvgData = ({ taskName, data, campaignId }) => async (
  dispatch
) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    let csvCustomers = []

    for (let i = 0; i < data.length; i++) {
      let checkCustomerId = false
      let checkCustomerIdNumberInFile = 0
      let checkProductId = false
      let checkProductIdNumberInFile = 0
      let checkProductName = false
      let checkProductNameNumberInFile = 0
      let checkSiteId = false
      let checkSiteIdNumberInFile = 0
      let checkProductType = false
      let checkProductTypeNumberInFile = 0
      let checkRecurringId = false
      let checkRecurringIdNumberInFile = 0
      let amount = false
      let amountNumberInFile = 0

      for (let j = 0; j < data[0].length; j++) {
        if (data[0][j] === 'Purchase' || data[0][j] === 'purchase') {
          checkProductName = true
          checkProductNameNumberInFile = j
        }
      }

      for (let j = 0; j < data[0].length; j++) {
        if (data[0][j] === 'Amount' || data[0][j] === 'amount') {
          amount = true
          amountNumberInFile = j
        }
      }

      for (let j = 0; j < data[0].length; j++) {
        if (
          data[0][j] === 'CustomerID' ||
          data[0][j] === 'Customer ID' ||
          data[0][j] === 'Customer Id' ||
          data[0][j] === 'Customer id' ||
          data[0][j] === 'Customer_id' ||
          data[0][j] === 'Customer_id' ||
          data[0][j] === 'Customer  ID'
        ) {
          checkCustomerId = true
          checkCustomerIdNumberInFile = j
        }
      }

      for (let j = 0; j < data[0].length; j++) {
        if (
          data[0][j] === 'Product_id' ||
          data[0][j] === 'Product ID' ||
          data[0][j] === 'Product Id' ||
          data[0][j] === 'Product id' ||
          data[0][j] === 'Product_ID' ||
          data[0][j] === 'Product_Id' ||
          data[0][j] === 'Product  ID'
        ) {
          checkProductId = true
          checkProductIdNumberInFile = j
        }
      }

      for (let j = 0; j < data[0].length; j++) {
        if (
          data[0][j] === 'Site_id' ||
          data[0][j] === 'Site ID' ||
          data[0][j] === 'Site Id' ||
          data[0][j] === 'Site id' ||
          data[0][j] === 'Site_ID' ||
          data[0][j] === 'Site_Id' ||
          data[0][j] === 'Site  ID'
        ) {
          checkSiteId = true
          checkSiteIdNumberInFile = j
        }
      }

      for (let j = 0; j < data[0].length; j++) {
        if (
          data[0][j] === 'Product_type' ||
          data[0][j] === 'Product type' ||
          data[0][j] === 'Product Type' ||
          data[0][j] === 'Product_Type' ||
          data[0][j] === 'Product  type'
        ) {
          checkProductType = true
          checkProductTypeNumberInFile = j
        }
      }

      for (let j = 0; j < data[0].length; j++) {
        if (
          data[0][j] === 'Recurring_id' ||
          data[0][j] === 'Recurring ID' ||
          data[0][j] === 'Recurring Id' ||
          data[0][j] === 'Recurring id' ||
          data[0][j] === 'Recurring_ID' ||
          data[0][j] === 'Recurring_Id' ||
          data[0][j] === 'Interval ID' ||
          data[0][j] === 'Interval_ID' ||
          data[0][j] === 'Interval_Id' ||
          data[0][j] === 'interval_Id' ||
          data[0][j] === 'Recurring  ID'
        ) {
          checkRecurringId = true
          checkRecurringIdNumberInFile = j
        }
      }

      if (
        checkCustomerId &&
        checkProductId &&
        checkSiteId &&
        checkProductType &&
        checkRecurringId &&
        amount &&
        checkProductName
      ) {
        if (i > 0 && i < data.length && data[i].length > 1) {
          csvCustomers.push({
            campaignId: campaignId,
            productType: data[i][checkProductTypeNumberInFile],
            productName: data[i][checkProductNameNumberInFile],
            recurringId: data[i][checkRecurringIdNumberInFile],
            productId: data[i][checkProductIdNumberInFile],
            externalId: data[i][checkCustomerIdNumberInFile],
            externalSiteId: data[i][checkSiteIdNumberInFile],
            amount: data[i][amountNumberInFile],
          })
          await axios.post(`${settings.backendUrl}/graphql`, {
            query: CSV_CUSTOMERS,
            variables: {
              campaignId: campaignId,
              taskName: taskName,
              recurringId: data[i][checkRecurringIdNumberInFile],
              productId: data[i][checkProductIdNumberInFile],
              externalId: data[i][checkCustomerIdNumberInFile],
              externalSiteId: data[i][checkSiteIdNumberInFile],
              amount: data[i][amountNumberInFile],
            },
          })
        }
      } else {
        throw new Error('CSV file not valid!')
      }
    }

    dispatch(successNotification('Download Customers successful'))

    dispatch({
      type: types.SET_CSV_CUSTUMERS_LIST,
      data: csvCustomers,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//removeCsvCustomers
export const removeCsvCustomers = () => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    dispatch({
      type: types.REMOVE_CSV_CUSTUMERS_LIST,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (error) {}
}

//errorDownloadCustomers
export const errorDownloadCustomers = (error) => async (dispatch) => {
  dispatch(errorNotification(error))
}

//fetchCustomersList
export const fetchCustomersList = ({
  page,
  size,
  filters = {},
  taskId,
}) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: FETCH_CUSTOMERS_LIST,
        variables: { page, size, filters, taskId },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { customersListPage } = data
    if (!customersListPage) {
      throw new Error("Can't get customers")
    }

    dispatch({
      type: types.SET_CUSTUMERS_LIST,
      data: customersListPage,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//removeCustomer
export const removeCustomer = (externalId) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: REMOVE_CUSTOMER,
        variables: { externalId },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeCustomer } = data
    if (!removeCustomer) {
      throw new Error("Can't remove customer")
    }

    dispatch({
      type: types.REMOVE_CUSTOMER,
      data: removeCustomer,
    })

    dispatch(successNotification('Remove Customer successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//downloadCustomers
export const downloadCustomers = () => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: DOWNLOAD_CUSTOMERS,
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    dispatch(successNotification('Download customers successful'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//getCustomer
export const getCustomer = (customerId) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: GET_CUSTOMER,
        variables: { customerId: customerId },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { customer } = data

    if (!customer) {
      throw new Error("Can't get customer")
    }

    dispatch({
      type: types.SET_CUSTOMER,
      data: customer,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}
