import React, {  useState } from 'react'
import { connect } from 'react-redux'
import useReactRouter from 'use-react-router'
import { Link } from 'react-router-dom'
import * as actions from './actions'
import logo from '../../assets/images/logo.png'

const Login = ({ login }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })
  const [errors, setErrors] = useState({})

  const { history } = useReactRouter()

  const hadlerFormData = (event, key) => {
    const newFormData = { ...formData }
    const newErrors = { ...errors }
    newErrors[key] = !event.target.value ? 'This field can\'t be empty' : ''

    if (key === 'email' && event.target.value.search(/.+@.+\..+/i) === -1) {
      newErrors.email = 'Invalid email'
    }
    setErrors(newErrors)
    newFormData[key] = event.target.value
    setFormData(newFormData)
  }

  const handlerSubmit = () => {
    let newErrors = {}
    Object.keys(formData).forEach(item => {
      if (!formData[item]) {
        newErrors[item] = 'This field can\'t be empty'
      }
    })
    newErrors = { ...newErrors, ...errors }
    setErrors(newErrors)
    let result = true
    Object.keys(newErrors).forEach(item => {
      result = result && !newErrors[item]
    })

    if (result) {
      login({ ...formData, history })
    }
  }
  return (
    <div className="wrapper  pa-0">
      <header className="sp-header">
        <div className="sp-logo-wrap pull-left">
          <Link className="goIndex" to="/">
            <img className="brand-img mr-10" src={logo} alt="brand" />
          </Link>
        </div>
        <div className="form-group mb-0 pull-right">
          <span className="inline-block pr-10">Don't have an account?</span>
          <Link className="inline-block btn btn-success  btn-rounded btn-outline" to="/signup">Sign Up</Link>
        </div>
        <div className="clearfix"></div>
      </header>

      <div className="page-wrapper pa-0 ma-0 auth-page">
        <div className="container-fluid">
          <div className="table-struct full-width full-height">
            <div className="table-cell vertical-align-middle auth-form-wrap">
              <div className="auth-form  ml-auto mr-auto no-float">
                <div className="row">
                  <div className="col-sm-12 col-xs-12">
                    <div className="mb-30">
                      <h3 className="text-center txt-dark mb-10">Sign in</h3>
                      <h6 className="text-center nonecase-font txt-grey">Enter your details below</h6>
                    </div>
                    <div className="form-wrap">
                      <div>
                        <div className="form-group">
                          <label className="control-label mb-10" htmlFor="exampleInputEmail_2">Email address</label>
                          <input type="email" onChange={event => hadlerFormData(event, 'email')} className="form-control" required placeholder="Enter email" />
                        </div>
                        <div className="form-group">
                          <label className="pull-left control-label mb-10" htmlFor="exampleInputpwd_2">Password</label>
                          <a className="capitalize-font txt-primary block mb-10 pull-right font-12" href="forgot-password.html">forgot password ?</a>
                          <div className="clearfix"></div>
                          <input type="password" onChange={event => hadlerFormData(event, 'password')} className="form-control" required placeholder="Enter pwd" />
                        </div>

                        <div className="form-group">
                          <div className="checkbox checkbox-primary pr-10 pull-left">
                            <input id="checkbox_2" required="" type="checkbox" />
                            <label htmlFor="checkbox_2"> Keep me logged in</label>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                        <div className="form-group text-center">
                          <button onClick={handlerSubmit} className="btn btn-success  btn-rounded">sign in</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

const mapDispatchToProps = {
  login: actions.login
}

export default connect(null, mapDispatchToProps)(Login)
