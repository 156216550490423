import { types } from './types'
import axios from '../../utils/axios'
import settings from '../../settings'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import {
  errorNotification,
  successNotification,
} from '../../utils/notificationHelper'

const GET_CLIENT_LIST = `
  query UsersListPage($page: Int, $size: Int, $filters: UsersFilters!) {
    usersListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        email
        firstName
        lastName
        companyName
        address1
        address2
        phone
        city
        state
        apiKey
        zip
        active
      }
      total
    }
  }
`

const userListInfo = `
  query UserList($userId: ID) {
    userList(userId: $userId) {
        email
        firstName
        lastName
        companyName
        firstName
        lastName
        address1
        address2
        phone
        city
        state
        zip
        active
    }
  }
`

const createClientQuery = `
  mutation RegisterUser($userInput: UserInput!) {
    registerUser(userInput: $userInput)
  }
`

const updateClientQuery = `
  mutation updateUser($userId: ID!, $firstName: String, $email: String, $country: String, $lastName: String, $companyName: String, $address1: String, $address2: String, $phone: String, $city: String, $state: String, $zip: String) {
    updateUser(userId: $userId, firstName: $firstName, email: $email, country: $country, lastName: $lastName, companyName: $companyName, address1: $address1, address2: $address2, phone: $phone, city: $city, state: $state, zip: $zip)
  } 
`

const removeUserQuery = `
  mutation RemoveUser($userId: ID!) {
    removeUser(userId: $userId)
  }
`
const SET_ACTIVE_USER = `
  mutation setActiveUser($userId: ID!, $active: Boolean) {
    setActiveUser(userId: $userId, active: $active)
  }
`

const FETCH_USERS_LIST = `
 query UsersListPage($page: Int, $size: Int, $filters: UsersFilters!) {
    usersListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        firstName
      }
      total
    }
  }
`

export const setActiveUser = ({ userId, active }) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: SET_ACTIVE_USER,
        variables: { userId, active },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { setActiveUser } = data
    if (!setActiveUser) {
      throw new Error('Wrong request')
    }

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

export const getClientsList = ({ page, size, filters = {} }) => async (
  dispatch
) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: GET_CLIENT_LIST,
        variables: { page, size, filters },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { usersListPage } = data
    if (!usersListPage) {
      throw new Error('Wrong request')
    }

    dispatch({
      type: types.SET_CLIENTS_LIST,
      data: usersListPage,
    })
    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    // const errorMessage = extractErrorInfo(errors)
    // dispatch(errorNotification(errorMessage))
  }
}

export const createClient = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: createClientQuery,
        variables: { userInput: formData },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { registerUser } = data
    if (!registerUser) {
      throw new Error('Wrong request')
    }

    dispatch(successNotification('Create User successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

export const getClientList = ({ userId }) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: userListInfo,
        variables: { userId },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { clientInfo } = data
    if (!clientInfo) {
      throw new Error('Wrong request')
    }

    dispatch({
      type: types.SET_CLIENT_LIST,
      data: clientInfo,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

export const updateClientInfo = (upData) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: updateClientQuery,
        variables: {
          userId: upData.id,
          firstName: upData.firstName,
          email: upData.email,
          country: upData.country,
          lastName: upData.lastName,
          companyName: upData.companyName,
          address1: upData.address1,
          address2: upData.address2,
          phone: upData.phone,
          city: upData.city,
          state: upData.state,
          zip: upData.zip,
        },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    dispatch(successNotification('User update successful'))
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

export const removeUser = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: removeUserQuery,
        variables: { userId },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeUser } = data
    if (!removeUser) {
      throw new Error("Can't remove user")
    }
    dispatch({
      type: types.REMOVE_USER,
      data: userId,
    })

    dispatch(successNotification('User removed successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

export const fetchUsersList = ({ page, size, filters = {} }) => async (
  dispatch
) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: FETCH_USERS_LIST,
        variables: { page, size, filters },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { usersListPage } = data
    if (!usersListPage) {
      throw new Error("Can't get users")
    }
    dispatch({
      type: 'SET_USERS_FOR_OPTIONS',
      data: usersListPage,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}
