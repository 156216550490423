import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Main } from '../../../components'
import { AddNewGroup } from '../components'
import { Popconfirm, Table } from 'antd'
import * as actions from '../actions'
import UpdateGroup from '../components/UpdateGroup'
import { Link } from 'react-router-dom'

const GroupsListPage = ({
  setGroupList,
  groups,
  removeGroup,
  compaigns,
  compaign,
  total,
}) => {
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    const campaignItem = JSON.parse(window.localStorage.getItem('campaign'))
    if (campaignItem !== '' && campaignItem) {
      setGroupList({
        page: pageNumber,
        size: pageSize,
        filters: { campaignId: campaignItem.id, type: 'group' },
      })
    } else {
      setGroupList({
        page: pageNumber,
        size: pageSize,
        filters: { type: 'group' },
      })
    }
  }, [pageSize, pageNumber, compaign])

  const doConfirm = async (groupId, e) => {
    e && e.stopPropagation()
    await removeGroup(groupId)
    setGroupList({ page: 1, size: 100, filters: { type: 'group' } })
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Corporation Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Compaign Name',
      dataIndex: 'campaignId',
      key: 'campaignId',
      render: (record) => {
        let name = ''
        // eslint-disable-next-line array-callback-return
        compaigns.map((item) => {
          if (+record === +item.id) {
            name = item.name
          }
        })
        return (
          <div>
            {name}({record})
          </div>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: (record) => (
        <>
          <UpdateGroup record={record} />
          <Popconfirm
            title="Are you sure delete this Product?"
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText="Yes"
            cancelText="No"
          >
            <i
              className="zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item"
              href="/#"
              title="Delete"
            ></i>
          </Popconfirm>
          <Link
            to={{
              pathname: `/rules:groupId=${record.id}`,
              group: record.id,
              campaignId: record.campaignId,
            }}
          >
            <i className="zmdi zmdi-window-restore zmdi-hc-2x"></i>
          </Link>
        </>
      ),
    },
  ]

  const changePaginataion = (data) => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }

  return (
    <Main>
      <AddNewGroup callback={() => {}} />
      <Table
        columns={columns}
        dataSource={groups}
        bordered
        rowKey={(record) => record.id}
        onChange={changePaginataion}
        pagination={{
          total,
          showSizeChanger: true,
          pageSize,
          current: pageNumber,
        }}
      />
    </Main>
  )
}

const mapStateToProps = (store) => ({
  groups: store.groups.list,
  total: store.groups.total,
  compaigns: store.companies.list,
  compaign: store.companies.compaign,
})

const mapDispatchToPtops = {
  setGroupList: actions.setGroupList,
  removeGroup: actions.removeGroup,
}

export default connect(mapStateToProps, mapDispatchToPtops)(GroupsListPage)
