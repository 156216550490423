import { types } from './types'

const initState = {
  list: [],
  cycles: [],
  customersStat: [],
  customerProductsStat: [],
  taskStat: [],
  total: 0,
  customerProductsStatTotal: 0,
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_TASKS_LIST: {
      const { data } = action
      return {
        ...state,
        list: data.items,
        total: data.total,
      }
    }
    case types.SET_TASK_CUSTOMER_PRODUCTS_LIST: {
      const { data } = action
      return {
        ...state,
        customerProductsStat: data.items,
        customerProductsStatTotal: data.total,
      }
    }
    case types.SET_TASKS_STATUS_AND_DATE_START: {
      const { data } = action
      return {
        ...state,
        list: state.list.map((task) => {
          if (data.id === task.id) {
            task.status = data.status
            task.runDate = data.runDate
            task.runningMid = data.runningMid
          }
          return task
        }),
      }
    }
    case types.SET_CUSTOMERS_INFO: {
      const { data } = action
      return {
        ...state,
        list: state.list.map((task) => {
          if (data.id === task.id) {
            task.customLenght = data.customLenght
            task.successCustom = data.successCustom
            task.unsuccessCustom = data.unsuccessCustom
            task.runningMid = data.runningMid
          }
          return task
        }),
      }
    }
    case types.SET_CYCLES: {
      const { data } = action
      return {
        ...state,
        cycles: data,
        cyclesTotal: data.total,
      }
    }
    case types.SET_TASK_STATISTICS: {
      const { data } = action
      return {
        ...state,
        taskStat: data,
      }
    }
    case types.SET_TASK_CUSTOMERS_STATISTICS: {
      const { data } = action
      return {
        ...state,
        customersStat: data.items,
        customersStatTotal: data.total,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
