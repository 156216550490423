const initState = {
  active: false
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_LOADING_STATE': {
      const { active } = action
      return {
        ...state,
        active
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
