import axios from '../../utils/axios'
import settings from '../../settings'
import { types } from './types'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import {
  errorNotification,
  successNotification,
} from '../../utils/notificationHelper'

const SET_GROUP_LIST = `
query groupsListPage($page: Int, $size: Int, $filters: GroupsFiltersType!) {
    groupsListPage(page: $page, size: $size, filters: $filters) {
    items {
      id
      campaignId
      name
      transMax
    }
    total
  }
}
`

const CREATE_GROUP = `
  mutation createGroup($name: String!, $campaignId: ID!, $transMax: Int!, $type: String) {
    createGroup(name: $name, campaignId: $campaignId, transMax: $transMax, type: $type) {
      id
    }
  }
`

const UPDATE_GROUP = `
  mutation updateGroup($name: String!, $campaignId: ID!, $groupId: ID!, $transMax: Int!) {
    updateGroup(name: $name, campaignId: $campaignId, groupId: $groupId, transMax: $transMax)
  }
`

const REMOVE_GROUP = `
  mutation deleteGroup($groupId: ID!) {
    deleteGroup(groupId: $groupId)
  }
`

//updateGroup
export const updateGroup =
  ({ name, campaignId, groupId, transMax }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      let { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: UPDATE_GROUP,
          variables: {
            name,
            campaignId,
            groupId,
            transMax,
          },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { updateGroup } = data
      if (!updateGroup) {
        throw new Error("Can't update Rule")
      }

      dispatch(successNotification('Update Corporation successful!'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//removeGroup
export const removeGroup = (groupId) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: REMOVE_GROUP,
        variables: { groupId },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { deleteGroup } = data
    if (!deleteGroup) {
      throw new Error("Can't remove group")
    }

    dispatch({
      type: types.REMOVE_GROUP,
      data: groupId,
    })

    dispatch(successNotification('Remove Corporation successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//addGroup
export const addGroup =
  ({ name, campaignId, transMax }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })

      let { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: CREATE_GROUP,
          variables: {
            name,
            campaignId,
            transMax,
            type: 'corp',
          },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { createGroup } = data
      if (!createGroup) {
        throw new Error("Can't create Rule")
      }

      dispatch(successNotification('Create Corporation successful'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//setGroupList
export const setGroupList =
  ({ page, size, filters = {} }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: SET_GROUP_LIST,
          variables: { page, size, filters },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { groupsListPage } = data
      if (!groupsListPage) {
        throw new Error("Can't get group")
      }

      dispatch({
        type: types.SET_GROUP_LIST,
        data: groupsListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }
