import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Input,
  Form,
  Select,
  Table,
  Popconfirm,
  InputNumber,
} from 'antd'
import { AddProduct } from '../components'
import { AddProcessor } from '../components'
import { UpdateProcessor } from '../components'
import * as actions from '../actions'
import * as productsActions from '../../Products/actions'
import * as processorsActions from '../../Processors/actions'

const UpdateRule = ({
  record,
  onCancel,
  products,
  setProductsList,
  ruleProducts,
  ruleProcessors,
  updateRule,
  setRuleProductsList,
  setRuleProcessorsList,
  deleteRuleProcessor,
  fetchProcessorsList,
  setRulesList,
}) => {
  const [form] = Form.useForm()
  const [productsRule, setProductsRule] = useState([])
  const [sendProductsRule, setSendProductsRule] = useState([])
  const [processorsRule, setProcessorsRule] = useState([])
  const [sendProcessorsRule, setsendProcessorsRule] = useState([])
  const [sumError, setSumError] = useState('')

  useEffect(() => {
    form.resetFields()
    setRuleProductsList({
      page: 1,
      size: 100,
      filters: { campaignId: record.campaignId },
      action: 'ruleInfo',
    })
    setRuleProcessorsList({
      page: 1,
      size: 100,
      filters: { ruleId: record.id },
      action: 'ruleInfo',
    })
    setProductsList({
      page: 1,
      size: 1000,
      filters: { campaignId: record.campaignId },
    })
    fetchProcessorsList({
      page: 1,
      size: 1000,
      filters: { campaignId: record.campaignId },
    })
  }, [
    fetchProcessorsList,
    form,
    record,
    setProductsList,
    setRuleProcessorsList,
    setRuleProductsList,
  ])

  useEffect(() => {
    let productsRule = []
    if (ruleProducts) {
      ruleProducts.map((item) => {
        if (item.ruleId === record.id) {
          productsRule.push(item)
        }
      })
      setProductsRule(productsRule)
    }
  }, [ruleProducts])

  useEffect(() => {
    setProcessorsRule(ruleProcessors)
  }, [ruleProcessors])

  const onFinishHandler = async (values) => {
    let sumPercent = 0
    for (let i = 0; i < processorsRule.length; i++) {
      sumPercent += +processorsRule[i].processorPercent
    }
    if (sumPercent !== 100) {
      setSumError('sum percent processors must be 100%!')
    } else {
      if (sendProductsRule.length === 0) {
        let poroductsEdit = productsRule.map((product) => {
          return product.exProductId
        })
        await updateRule({
          groupId: +values.groupId,
          ruleId: +values.ruleId,
          sendProductsRule: poroductsEdit,
          sendProcessorsRule,
        })
        setSumError('')
      } else {
        let poroductsEdit = productsRule.map((product) => {
          return product.exProductId
        })
        let updateResult = await updateRule({
          groupId: +values.groupId,
          ruleId: +values.ruleId,
          sendProductsRule: poroductsEdit,
          sendProcessorsRule,
        })
        if (!updateResult) {
          setProductsRule(ruleProducts)
          setProcessorsRule(ruleProcessors)
          setSendProductsRule([])
          setsendProcessorsRule([])
        } else {
          setRulesList({
            page: 1,
            size: 10,
            filters: { groupId: +values.groupId },
          })
          setSendProductsRule([])
          setsendProcessorsRule([])
          setProductsRule([])
          setProcessorsRule([])
          onCancel(sumPercent)
          form.resetFields()
        }
        setSumError('')
      }
    }
  }

  const doConfirm = async ({ prodId, procId, exProcId, e }) => {
    if (prodId) {
      const leftoverProducts = productsRule.filter(
        (product) => product.exProductId !== prodId
      )
      const sendproducts = leftoverProducts.map((product) => {
        return product.exProductId
      })
      setSendProductsRule([...sendproducts])
      setProductsRule(leftoverProducts)
    }
    if (exProcId) {
      const leftoverProcessors = processorsRule.filter(
        (processor) => processor.exProcessorId !== procId
      )
      setsendProcessorsRule([...sendProcessorsRule, exProcId])
      setProcessorsRule(leftoverProcessors)
      await deleteRuleProcessor({ ruleProcessorId: procId })
      setRuleProcessorsList({
        page: 1,
        size: 100,
        filters: { ruleId: record.id },
      })
    }
    e && e.stopPropagation()
  }

  const processorsColumns = [
    {
      title: 'ID',
      dataIndex: 'exProcessorId',
      key: 'exProcessorId',
    },
    {
      title: 'Rule Name',
      dataIndex: 'name',
      key: 'name',
      render: (record) => {
        return <div>{record}</div>
      },
    },
    {
      title: 'Rule ID',
      dataIndex: 'ruleId',
      key: 'ruleId',
      render: (record) => {
        return <div>{record}</div>
      },
    },
    {
      title: 'Percent',
      dataIndex: 'processorPercent',
      key: 'processorPercent',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: (processorsData) => {
        return (
          <div>
            <UpdateProcessor data={processorsData} />
            <Popconfirm
              title="Are you sure delete this Rule?"
              onConfirm={(e) =>
                doConfirm({
                  exProcId: processorsData.exProcessorId,
                  procId: processorsData.id,
                  e,
                })
              }
              onCancel={(e) => e?.stopPropagation()}
              okText="Yes"
              cancelText="No"
            >
              <i
                className="zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item"
                href="/#"
                title="Delete"
              ></i>
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  const productsColumns = [
    {
      title: 'ID',
      dataIndex: 'exProductId',
      key: 'exProductId',
      render: (record) => {
        const existProduct = products.find(
          (product) => product.externalId === record
        )
        if (existProduct) {
          return (
            <div>
              {record} - {existProduct.name}
            </div>
          )
        }
      },
    },
    {
      title: 'Rule ID',
      dataIndex: 'ruleId',
      key: 'ruleId',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: (productData) => {
        return (
          <>
            <Popconfirm
              title="Are you sure delete this Rule?"
              onConfirm={(e) =>
                doConfirm({ prodId: productData.exProductId, e })
              }
              onCancel={(e) => e?.stopPropagation()}
              okText="Yes"
              cancelText="No"
            >
              <i
                className="zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item"
                href="/#"
                title="Delete"
              ></i>
            </Popconfirm>
          </>
        )
      },
    },
  ]

  const updateProductsRule = (products) => {
    const newProductsRule = [
      ...productsRule,
      ...products.map((item) => ({
        exProductId: item.externalId,
        ruleId: record.id,
      })),
    ]
    setProductsRule(newProductsRule)
    const newProductsSendRule = products.map((product) => {
      return product.externalId
    })
    setSendProductsRule([...productsRule, ...newProductsSendRule])
  }

  const checkPrecentSum = () => {
    let sumPercent = 0
    for (let i = 0; i < processorsRule.length; i++) {
      sumPercent += +processorsRule[i].processorPercent
    }
    if (sumPercent !== 100) {
      setSumError('sum percent processors must be 100%!')
    } else {
      onCancel()
    }
  }

  return (
    <>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        className="update_rule_modal"
        style={{
          padding: '10px',
        }}
      >
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Form.Item
              initialValue={record.groupId}
              name="groupId"
              label="CorporationID"
              style={{ marginRight: '10px' }}
            >
              <InputNumber disabled />
            </Form.Item>

            <Form.Item initialValue={record.id} name="ruleId" label="Rule ID">
              <InputNumber disabled />
            </Form.Item>
          </div>
          <h4 className="txt-dart">Rule Products</h4>
          <AddProduct ruleId={record.id} onAddProduct={updateProductsRule} />
          <Table
            columns={productsColumns}
            dataSource={productsRule}
            bordered
            rowKey={(record) => record.exProductId}
            onRow={() => {}}
          />
          <h4 className="txt-dart">Rule Processors</h4>
          <AddProcessor ruleId={record.id} />
          <Table
            columns={processorsColumns}
            dataSource={processorsRule}
            bordered
            rowKey={(record) => record.id}
            onRow={() => {}}
          />
        </div>

        <div
          style={{
            position: 'absolute',
            left: 0,
            display: 'flex',
            justifyContent: 'space-between',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button onClick={() => checkPrecentSum()}>Close</Button>
          <div>{sumError}</div>
          <Button
            type="primary"
            htmlType="submit"
            className="add-new-site_button"
          >
            Edit
          </Button>
        </div>
      </Form>
    </>
  )
}

const mapStateToProps = (store) => ({
  groups: store.groups.list,
  ruleProducts: store.rules.ruleProductslist,
  products: store.products.list,
  processors: store.processors.list,
  ruleProcessors: store.rules.ruleProcessorslist,
})

const mapDispatchToProps = {
  setRulesList: actions.setRulesList,
  updateRule: actions.updateRule,
  setRuleProcessorsList: actions.setRuleProcessorsList,
  setRuleProductsList: actions.setRuleProductsList,
  deleteRuleProcessor: actions.deleteRuleProcessor,
  fetchProcessorsList: processorsActions.fetchProcessorsList,
  setProductsList: productsActions.setProductsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRule)
