import React, { useEffect } from 'react'
import 'antd/dist/antd.css'
import { Select } from 'antd'
import { connect } from 'react-redux'
import * as companyActions from '../../Companies/actions'
import * as sitesActions from '../../Sites/actions'

const CompanyFilter = ({ fetchCompaniesList, companies, fetchSitesList }) => {

  useEffect(() => {
    fetchCompaniesList({ page: 1, size: 100, filters: {} })
  }, [fetchCompaniesList])

  const onChange = async value => {
    if (value === 'all'){ 
      fetchSitesList({ page: 1, size: 100, filters: {} })
    } else {
      fetchSitesList({ page: 1, size: 100, filters: { campaignId: value } })
    }
  }

  const onSearch = (val) => {
  }

  const { Option } = Select

  return (
    <>
        <Select
        style={{  width: 200, marginLeft: 20, marginRight: 20  }}
        showSearch
        placeholder="select a company"
        optionFilterProp="children"
        onChange={ onChange }
        onSearch={ onSearch }
        filterOption={ (input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        >
        <Option value='all'>All Companies</Option>
        {companies.map(company => (
            <Option key={ company.id } value={ company.id }>{ company.name }</Option>
        ))}
        </Select>
    </>
  )
}

const mapStateToProps = store => ({
  companies: store.companies.list
})

const mapDispatchToProps = {
  fetchSitesList: sitesActions.fetchSitesList,
  fetchCompaniesList: companyActions.fetchCompaniesList
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyFilter)
