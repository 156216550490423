import axios from '../../utils/axios'
import settings from '../../settings'
import { types } from './types'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import {
  errorNotification,
  successNotification,
} from '../../utils/notificationHelper'

const FETCH_PROCESSORS_LIST = `
  query processorsListPage($page: Int, $size: Int, $filters: ProcessorsFilters!) {
    processorsListPage(page: $page, size: $size, filters: $filters) {
      items {
        id
        name 
        campaignId
        externalId
        percentageUsed
        limit
      }
      total
    }
  }
`

const ADD_NEW_PROCESSOR = `
  mutation createProcessor($campaignId: ID!, $action: String!) {
    createProcessor(campaignId: $campaignId, action: $action)
}
`

const UPDATE_PROCESSOR = `
  mutation updateProcessor($processorId: ID!, $name: String, $test: String, $currency: String, $limit: String) {
    updateProcessor(processorId: $processorId, name: $name, test: $test, currency: $currency, limit: $limit)
  }
`

const REMOVE_PROCESSOR = `
  mutation removeProcessor($processorId: ID!) {
    removeProcessor(processorId: $processorId)
  }
`

// fetchProcessorsList
export const fetchProcessorsList = ({ page, size, filters = {} }) => async (
  dispatch
) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: FETCH_PROCESSORS_LIST,
        variables: { page, size, filters },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is ampty!')
    }

    const { processorsListPage } = data
    if (!processorsListPage) {
      throw new Error("Can't get processors")
    }

    dispatch({
      type: types.SET_PROCESSORS_LIST,
      data: processorsListPage,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

// createProcessor
export const createProcessor = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: ADD_NEW_PROCESSOR,
        variables: { ...formData },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response Body Is Empty!')
    }

    const { createProcessor } = data
    if (!createProcessor) {
      throw new Error('The operation was not successful!')
    }

    dispatch(successNotification('Operation Completed Successfully!'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

// updateProcessor
export const updateProcessor = (args) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { processorId, name, test, currency, limit } = args

    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: UPDATE_PROCESSOR,
        variables: {
          processorId,
          name,
          test,
          currency,
          limit: limit.toString(),
        },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { updateProcessor } = data
    if (!updateProcessor) {
      throw new Error("Can't Update Processor")
    }

    dispatch(successNotification('Processor Update Successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const arrorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(arrorMessage))
  }
}

// removeProcessor
export const removeProcessor = (processorId) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: REMOVE_PROCESSOR,
        variables: { processorId },
      }
    )

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { removeProcessor } = data
    if (!removeProcessor) {
      throw new Error("Can't remove processor")
    }

    dispatch(successNotification('Remove Processor successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}
