import types from './types'

const initState = {
  list: [],
  total: 0,
  totalCampaignsUsers: 0,
  companiesForOptions: [],
  companiesUsers: [],
  compaign: '',
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_COMPANIES_USERS_LIST: {
      const { data } = action
      return {
        ...state,
        companiesUsers: data.items,
        totalCampaignsUsers: data.total,
      }
    }
    case types.SET_COMPANIES_LIST: {
      const { data } = action
      return {
        ...state,
        list: data.items,
        total: data.total,
      }
    }
    case types.REMOVE_COMPANY: {
      state.list = state.list.filter((item) => item.id !== action.data)
      state.total = state.total === 0 ? state.total : state.total - 1
      return {
        ...state,
      }
    }
    case types.SET_COMPANIES_FOR_OPTIONS: {
      const { data } = action
      return {
        ...state,
        companiesForOptions: data,
      }
    }
    case types.UPDATE_COMPANY: {
      const { data } = action
      return {
        ...state,
        companiesForOptions: data,
      }
    }
    case types.SET_CAMPAIGN_ID: {
      const { data } = action
      return {
        ...state,
        compaign: data,
      }
    }
    case 'LOG_OUT': {
      return { ...initState }
    }
    default: {
      return state
    }
  }
}

export default reducer
