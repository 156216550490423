import React, { useState, useEffect } from 'react'
import { Table, Popconfirm, Button, Modal, Progress, Tag, Select } from 'antd'
import { Main } from '../../../components'
import * as actions from '../actions'
import * as sitesActions from '../../Sites/actions'
import { connect } from 'react-redux'
import { AddNewTask, StartTask } from '../components'
import TaskListInfo from '../components/TaskListInfo'
import TaskStatistics from '../components/TaskStatistics'

const TasksListPage = ({
  setTasksList,
  tasksList,
  removeTask,
  compaigns,
  sites,
  setSitesList,
  total,
  compaignId,
}) => {
  const [visible, setVisible] = useState(false)
  const [visibleInfoModal, setVisibleInfoModal] = useState(false)
  const [visibleStatModal, setVisibleStatModal] = useState(false)
  const [taskInfoRecord, setTaskInfoRecord] = useState([])
  const [taskStatRecord, setTaskStatRecord] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    const campaignItem = JSON.parse(window.localStorage.getItem('campaign'))
    if (campaignItem !== '' && campaignItem) {
      setTasksList({
        page: pageNumber,
        size: pageSize,
        filters: { companyId: campaignItem.id },
      })
    } else {
      setTasksList({
        page: pageNumber,
        size: pageSize,
        filters: {},
      })
    }
    setSitesList({ page: 1, size: 100 })
  }, [pageSize, pageNumber, compaignId])

  const rateCustomers = (suc, unsuc) => {
    let res = ''
    res = (+suc / (+unsuc + +suc)).toFixed(2) * 100
    res = Math.floor(res)
    if (+unsuc === 0 && +suc === 0) {
      res = '0'
    }
    if (+unsuc === 0 && +suc !== 0) {
      res = '100'
    }
    return res
  }

  const Completion = (a, b, sum) => {
    let res = ''
    res = ((+a + +b) / +sum).toFixed(2) * 100
    if (!res || !sum) {
      res = '0'
    }
    return res
  }

  const doConfirm = async (taskId, e) => {
    e && e.stopPropagation()
    await removeTask(taskId)
    setTasksList({ page: 1, size: 10 })
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Task Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Task Type',
      dataIndex: 'type',
      key: 'type',
      render: (record) => (
        <div>
          {record === 'global' ? (
            <Tag color="orange">{record}</Tag>
          ) : (
            <Tag color="blue">{record}</Tag>
          )}
        </div>
      ),
    },
    {
      title: 'SiteID',
      dataIndex: 'externalSiteId',
      key: 'externalSiteId',
      render: (record) => {
        if (record === 'undefined') {
          return <div>custom</div>
        } else {
          return <div>{record}</div>
        }
      },
    },
    {
      title: 'Campaign',
      dataIndex: 'companyId',
      key: 'companyId',
      render: (record) => {
        let existCampaign = compaigns.find((element) => element.id === record)
        if (existCampaign) {
          return (
            <div>
              {existCampaign.name} ({record})
            </div>
          )
        }
      },
    },
    {
      title: 'Rate S/D',
      dataIndex: '',
      key: '',
      render: (record) => (
        <div>
          {rateCustomers(record.successCustom, record.unsuccessCustom)}% /{' '}
          {rateCustomers(record.successCustom, record.unsuccessCustom) !== '0'
            ? 100 - rateCustomers(record.successCustom, record.unsuccessCustom)
            : '0'}
          %
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (record) => (
        <div>
          {record === 'run' ? (
            <div className="status_button runTask_status-button">run</div>
          ) : record === 'paused' ? (
            <div className="pauseTask_status-button status_button">paused</div>
          ) : record === 'stop' ? (
            <div className="stopTask_status-button status_button">stoped</div>
          ) : record === 'finished' ? (
            <div className="finishTask_status-button status_button">
              finished
            </div>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'runDate',
      key: 'runDate',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: (record) => {
        return (
          <>
            <StartTask record={record} />

            {record.status === 'run' || record.status === 'paused' ? (
              <i
                className="zmdi zmdi-chart zmdi-hc-2x text-green users-list-action_item"
                color="yellow"
                title="Task Statistics"
                type="primary"
                onClick={() => showTaskStatistics({ taskInfo: record, date })}
              />
            ) : (
              <div>
                <Popconfirm
                  title="Are you sure delete this Task?"
                  onConfirm={(e) => doConfirm(record.id, e)}
                  onCancel={(e) => e?.stopPropagation()}
                  okText="Yes"
                  cancelText="No"
                >
                  <i
                    className="zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item"
                    href="/#"
                    title="Delete"
                  ></i>
                </Popconfirm>
                <i
                  className="zmdi zmdi-edit zmdi-hc-2x text-green users-list-action_item"
                  color="red"
                  title="Edit task"
                  type="primary"
                  onClick={() => showTaskInfo(record)}
                />
                <i
                  className="zmdi zmdi-chart zmdi-hc-2x text-green users-list-action_item"
                  color="yellow"
                  title="Task Statistics"
                  type="primary"
                  onClick={() => showTaskStatistics({ taskInfo: record, date })}
                />
              </div>
            )}
          </>
        )
      },
    },
  ]

  const date = new Date()

  const showModal = () => {
    setVisible(true)
  }

  const showTaskInfo = async (record) => {
    setTaskInfoRecord(record)
    setVisibleInfoModal(true)
  }

  const showTaskStatistics = async (record) => {
    setTaskStatRecord(record)
    setVisibleStatModal(true)
  }

  const handleCancel = () => {
    setVisible(false)
    setTaskInfoRecord('')
    setVisibleInfoModal(false)
    setVisibleStatModal(false)
  }

  const filterSite = (value) => {
    if (value === 'all') {
      setTasksList({ page: 1, size: 10, filters: {} })
    } else {
      setTasksList({ page: 1, size: 10, filters: { externalSiteId: value } })
    }
  }

  const changePaginataion = (data) => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }

  return (
    <Main>
      <h4 className="txt-dart">Tasks</h4>
      <div>
        <Button
          type="primary"
          onClick={showModal}
          className="add-new-site_button"
        >
          Create Task
        </Button>

        <Select
          className="task_filter_item"
          onSelect={filterSite}
          placeholder="select site"
        >
          <Select.Option value="all">All sites</Select.Option>
          {sites.map((site, key) => {
            return (
              <Select.Option key={key} value={site.externalId}>
                {site.externalId} - {site.name}
              </Select.Option>
            )
          })}
        </Select>
      </div>
      <Modal
        className="customer_modal"
        title="Create Task"
        visible={visible}
        footer={null}
        onCancel={handleCancel}
        width="70%"
      >
        <AddNewTask onCancel={handleCancel} />
      </Modal>

      <Modal
        className="customer_modal"
        title="Update Task"
        visible={visibleInfoModal}
        onCancel={handleCancel}
        footer={null}
        width="70%"
      >
        <TaskListInfo onCancel={handleCancel} record={taskInfoRecord} />
      </Modal>

      <Modal
        className="customer_modal"
        title="Task Detalis"
        visible={visibleStatModal}
        onCancel={handleCancel}
        footer={null}
        width="70%"
      >
        <TaskStatistics onCancel={handleCancel} record={taskStatRecord} />
      </Modal>

      <Table
        columns={columns}
        dataSource={tasksList}
        bordered
        rowKey={(record) => record.id}
        onChange={changePaginataion}
        pagination={{
          total,
          showSizeChanger: true,
          pageSize,
          current: pageNumber,
        }}
      />
    </Main>
  )
}

const mapStateToProps = (store) => ({
  tasksList: store.tasks.list,
  sites: store.sites.list,
  total: store.tasks.total,
  compaigns: store.companies.list,
  compaignId: store.companies.compaign,
})

const mapDispatchToProps = {
  setTasksList: actions.setTasksList,
  setSitesList: sitesActions.fetchSitesList,
  removeTask: actions.removeTask,
  updateStatusTask: actions.updateStatusTask,
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksListPage)
