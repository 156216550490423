import axios from '../../utils/axios'
import settings from '../../settings'
import { types } from './types'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import {
  errorNotification,
  successNotification,
  infoNotification,
} from '../../utils/notificationHelper'

const SET_RULES_LIST = `
query rulesListPage($page: Int, $size: Int, $filters: RulesFilters!) {
    rulesListPage(page: $page, size: $size, filters: $filters) {
    items {
      id
      groupId
      campaignId
      ruleProducts {
        exProductId
        name
      }
      ruleProcessors {
        exProcessorId
        name
      }
    }
    total
  }
}
`

const SET_RULEPRODUCTS_LIST = `
  query ruleProductsListPage($page: Int, $size: Int, $filters: ruleProductsFiltersType!,  $action: String) {
    ruleProductsListPage(page: $page, size: $size, filters: $filters, action: $action) {
      items {
        id
        ruleId
        exProductId
        campaignId
      }
      total
    }
  }
`

const SET_RULEPROCESSORS_LIST = `
  query ruleProcessorsListPage($page: Int, $size: Int, $filters:  RuleProcessorsFiltersType!, $action: String) {
    ruleProcessorsListPage(page: $page, size: $size, filters: $filters, action: $action) {
      items {
        id
        ruleId
        exProcessorId
        processorPercent
        name
      }
      total
    }
  }
`

const CREATE_RULE = `
  mutation createRule($groupId: ID!, $productsIds: String!, $processors: String!) {
    createRule(groupId: $groupId, productsIds: $productsIds, processors: $processors) {
      id
    }
  }
`

const SEND_RULE = `
  mutation sendRule($ruleId: ID!, $groupId: ID!) {
    sendRule(ruleId: $ruleId, groupId: $groupId) {
      id
    }
  }
`

const REMOVE_RULE = `
  mutation removeRule($ruleId: ID!) {
    removeRule(ruleId: $ruleId)
  }
`

const UPDATE_RULE = `
  mutation updateRule($ruleId: ID!, $groupId: ID!, $sendProducts: String!, $sendProcessors: String!) {
    updateRule(ruleId: $ruleId, groupId: $groupId, sendProducts: $sendProducts, sendProcessors: $sendProcessors)
  }
`

const DELETE_RULE_PRODUCT = `
  mutation removeRuleProduct($ruleProductId: String!, $ruleId: ID!) {
    removeRuleProduct(ruleProductId: $ruleProductId, ruleId: $ruleId)
  }
`

const DELETE_RULE_PROCESSOR = `
  mutation removeRuleProcessor($ruleProcessorId: ID!) {
    removeRuleProcessor(ruleProcessorId: $ruleProcessorId)
  }
`

const ADD_RULE_PRODUCT = `
  mutation createRuleProduct($ruleId: ID!, $exProductId: String!) {
    createRuleProduct(ruleId: $ruleId, exProductId: $exProductId) {
      id
    }
  }
`

const ADD_RULE_PROCESSOR = `
  mutation createRuleProcessor($ruleId: ID!, $exProcessorId: String!, $percent: Int!) {
    createRuleProcessor(ruleId: $ruleId, exProcessorId: $exProcessorId, percent: $percent) {
      id
    }
  }
`

const UPDATE_RULE_PROCESSOR = `
  mutation updateRuleProcessor($exProcessorId: String!, $percent: Int, $ruleId: ID!) {
    updateRuleProcessor(ruleId: $ruleId, exProcessorId: $exProcessorId, percent: $percent)
  }
`

//updateRuleProcessor
export const updateRuleProcessor =
  ({ ruleId, exProcessorId, percent }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: UPDATE_RULE_PROCESSOR,
          variables: { ruleId: +ruleId, exProcessorId, percent },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { updateRuleProcessor } = data
      if (!updateRuleProcessor) {
        throw new Error("Can't add processor in this rule!")
      }

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//groupId to rule page
export const setGroupIdToRule = (groupId) => async (dispatch) => {
  try {
    dispatch({
      type: types.SET_GROUP_ID,
      data: groupId,
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//addRuleProcessor
export const addRuleProcessor =
  ({ ruleId, exProcessorId, percent }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: ADD_RULE_PROCESSOR,
          variables: { ruleId, exProcessorId, percent },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { createRuleProcessor } = data
      if (!createRuleProcessor) {
        throw new Error("Can't add processor in this rule!")
      }

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//addRuleProduct
export const addRuleProduct =
  ({ ruleId, exProductId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: ADD_RULE_PRODUCT,
          variables: { ruleId, exProductId },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { createRuleProduct } = data
      if (!createRuleProduct) {
        throw new Error("Can't add product in this rule!")
      }

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//deleteRuleProduct
export const deleteRuleProduct =
  ({ ruleProductId, ruleId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: DELETE_RULE_PRODUCT,
          variables: { ruleProductId, ruleId },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { removeRuleProduct } = data
      if (!removeRuleProduct) {
        throw new Error("Can't delete product in this rule!")
      }

      dispatch(successNotification('Delete Product successful'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//deleteRuleProcessor
export const deleteRuleProcessor =
  ({ ruleProcessorId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: DELETE_RULE_PROCESSOR,
          variables: { ruleProcessorId },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { removeRuleProcessor } = data
      if (!removeRuleProcessor) {
        throw new Error("Can't delete processor in this rule!")
      }

      // dispatch(successNotification('Delete processor successful'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//updateRule
export const updateRule =
  ({ ruleId, groupId, sendProductsRule, sendProcessorsRule }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: UPDATE_RULE,
          variables: {
            ruleId,
            groupId,
            sendProducts: sendProductsRule.toString(),
            sendProcessors: sendProcessorsRule.toString(),
          },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { updateRule } = data
      if (!updateRule) {
        throw new Error("Can't update rule")
      }

      dispatch(successNotification('Update Rule successful'))

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
      return true
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
      return false
    }
  }

//deleteRule
export const deleteRule =
  ({ ruleId, action }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: REMOVE_RULE,
          variables: { ruleId: +ruleId },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { removeRule } = data
      if (!removeRule) {
        throw new Error("Can't remove rule")
      }

      dispatch({
        type: types.REMOVE_RULE,
        data: ruleId,
      })

      if (!action) {
        dispatch(successNotification('Remove Rule successful'))
      }

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//addRule
export const addRule =
  (groupId, products, processorsData) => async (dispatch) => {
    try {
      const productsIds = JSON.stringify(products)
      const processors = JSON.stringify(processorsData)

      let { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: CREATE_RULE,
          variables: {
            groupId: +groupId,
            productsIds,
            processors,
          },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { createRule } = data
      if (!createRule) {
        throw new Error("Can't create Rule")
      }
      return createRule.id
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//sendRule
export const sendRule = (ruleId, groupId) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })

    let { data, errors } = await axios.post(`${settings.backendUrl}/graphql`, {
      query: SEND_RULE,
      variables: {
        ruleId: +ruleId,
        groupId: +groupId,
      },
    })

    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { sendRule } = data
    if (!sendRule) {
      throw new Error("Can't send Rule")
    }

    dispatch(successNotification('Create Rule successful'))

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })

    return true
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

//setRulesList
export const setRulesList =
  ({ page, size, filters = {} }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: SET_RULES_LIST,
          variables: { page, size, filters },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { rulesListPage } = data
      if (!rulesListPage) {
        throw new Error("Can't get rules")
      }

      dispatch({
        type: types.SET_RULES_LIST,
        data: rulesListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(infoNotification(errorMessage))
    }
  }

//setRuleProductsList
export const setRuleProductsList =
  ({ page, size, filters, action }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: SET_RULEPRODUCTS_LIST,
          variables: { page, size, filters, action },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { ruleProductsListPage } = data
      if (!ruleProductsListPage) {
        throw new Error("Can't get rule products")
      }

      await dispatch({
        type: types.SET_RULEPRODUCTS_LIST,
        data: ruleProductsListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }

//setRuleProcessorsList
export const setRuleProcessorsList =
  ({ page, size, filters = {}, action }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LOADING_STATE',
        active: true,
      })
      const { data, errors } = await axios.post(
        `${settings.backendUrl}/graphql`,
        {
          query: SET_RULEPROCESSORS_LIST,
          variables: { page, size, filters, action },
        }
      )

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      const { ruleProcessorsListPage } = data
      if (!ruleProcessorsListPage) {
        throw new Error("Can't get ruleProcessors")
      }

      await dispatch({
        type: types.SET_RULEPPROCESSORS_LIST,
        data: ruleProcessorsListPage,
      })

      dispatch({
        type: 'SET_LOADING_STATE',
        active: false,
      })
    } catch (errors) {
      const errorMessage = extractErrorInfo(errors)
      dispatch(errorNotification(errorMessage))
    }
  }
