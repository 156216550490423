import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Input, Select, Form, Drawer, Button, InputNumber } from 'antd'
import * as compaignsActions from '../../Companies/actions'
import * as productsActions from '../../Products/actions'
import * as processorsActions from '../../Processors/actions'
import * as actions from '../actions'

const AddNewGroup = ({
  campaigns,
  callback,
  fetchCompaniesList,
  addGroup,
  setGroupList,
}) => {
  const [form] = Form.useForm()
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    fetchCompaniesList({ page: 1, size: 100 })
  }, [fetchCompaniesList])

  const onFinishHandler = async (value) => {
    await addGroup({
      name: value.name,
      campaignId: +value.campaignId,
      transMax: value.transMax,
    })
    setGroupList({ page: 1, size: 10, filters: { type: 'group' } })
    callback()
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <Button
        type="primary"
        className="add-new-site_button"
        onClick={() => setVisible(true)}
      >
        Add a Group
      </Button>

      <Drawer
        title="Add Group"
        width="400"
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
        >
          <Form.Item
            name="campaignId"
            label="Select a company"
            rules={[{ required: true, message: 'Select Company!' }]}
          >
            <Select>
              {campaigns.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="name"
            label="Input Corporation Name"
            rules={[{ required: true, message: 'Input Balancer Name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="transMax"
            label="Input transMax Value"
            rules={[
              {
                required: true,
                message: 'Input transMax Value!',
                type: 'number',
              },
            ]}
          >
            <InputNumber />
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button
              type="primary"
              htmlType="submit"
              className="add-new-site_button"
            >
              Add
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapStateToProps = (store) => ({
  campaigns: store.companies.list,
  processors: store.processors.list,
  products: store.products.list,
})

const mapDispatchToPtops = {
  fetchCompaniesList: compaignsActions.fetchCompaniesList,
  fetchProcessorsList: processorsActions.fetchProcessorsList,
  addGroup: actions.addGroup,
  setGroupList: actions.setGroupList,
  setProductsList: productsActions.setProductsList,
}

export default connect(mapStateToProps, mapDispatchToPtops)(AddNewGroup)
