import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form } from 'antd'
import * as actions from '../actions'

const UpdateCompany = ({
  pageNo,
  size,
  record,
  updateCompany,
  fetchCompaniesList,
}) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const onFinishHandler = async (values) => {
    await updateCompany({
      campaignId: values.id,
      name: values.name,
      guid: values.guid,
      paayKey: values.paayKey,
    })
    await fetchCompaniesList({ page: pageNo, size })
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <i
        className="zmdi zmdi-edit zmdi-hc-2x zmdi-hc-lg txt-warning users-list-action_item"
        title="Edit"
        type="primary"
        onClick={() => {
          setVisible(true)
        }}
      />
      <Drawer
        title="Update Site"
        width="400"
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
        >
          <Form.Item
            initialValue={record.name}
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please input site name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.guid}
            name="guid"
            label="Guid"
            rules={[{ required: true, message: 'Please input company guid!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.paayKey}
            name="paayKey"
            label="Paay Key"
            rules={[{ max: 200 }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.id}
            name="id"
            label="id"
            rules={[{ required: true, message: 'Please input site name!' }]}
          >
            <Input disabled />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button
              type="primary"
              htmlType="submit"
              className="add-new-site_button"
            >
              Update
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapDispatchToProps = {
  fetchCompaniesList: actions.fetchCompaniesList,
  updateCompany: actions.updateCompany,
}

export default connect(null, mapDispatchToProps)(UpdateCompany)
