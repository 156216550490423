/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { InputNumber, Form, Drawer, Button, Input } from 'antd'
import * as actions from '../actions'

const AddCycle = ({
  taskName,
  createCycleUpdateTask,
  setCycles,
  taskId
}) => {
  const [form] = Form.useForm()
  const [isVisible, setVisible] = useState(false)
  const [error, setError] = useState('')
  
  const onFinishHendler = async values => {
    let error = ''
    let okPrice = /^[\d\,\.]*$/.test(values.price)
    if (!okPrice) {
      error = 'wrong price'
      setError('wrong peice')
    }
    if (error === '') {
      await createCycleUpdateTask({ taskName: taskName, number: values.number, period: values.period, price: values.price })
      await setCycles({ page: 1, size: 100, filters: { taskId } })
      form.resetFields()
      setVisible(false)
    }
  }

  return (
    <div>
      <Button type="primary" className="add-new-site_button" onClick={() => setVisible(true)}>
        Add a Cycle
      </Button>

      <Drawer title='Add Cycle' width='400' onClose={() => setVisible(false)} visible={isVisible} forceRender>
				<Form layout='vertical' hideRequiredMark onFinish={ onFinishHendler } form={form}>

          <Form.Item
            name='price'
            fieldKey='price'
            label="Price"
            className="ask_modal-item__small"
            rules={[{
              min: 0,
              message: 'input price'
            }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name='period'
            fieldKey='period'
            label="Period"
            className="ask_modal-item__small"
            rules={[{
              type: 'number',
              min: 0,
              max: 30,
              message: 'input period, max 30 days'
            }]}
          >
            <InputNumber />
          </Form.Item>

					<div
						style={{
							position: 'absolute',
							left: 0,
							display: 'flex',
							justifyContent: 'space-between',
							bottom: 0,
							width: '100%',
							borderTop: '1px solid #e9e9e9',
							padding: '10px 16px',
							background: '#fff',
							textAlign: 'right'
						}}
					>
						<Button onClick={() => setVisible(false)}>
							Close
						</Button>
          <div>{ error }</div>
						<Button type='primary' htmlType='submit' className="add-new-site_button">
							Add
						</Button>
					</div>
				</Form>
			</Drawer>
    </div>
  )
}

const mapStateToProps = store => ({
})

const mapDispatchToPtops = {
  createCycleUpdateTask: actions.createCycleUpdateTask,
  setCycles: actions.setCycles
}

export default connect(mapStateToProps, mapDispatchToPtops)(AddCycle)
