import { types } from './types'
import axios from '../../utils/axios'
import settings from '../../settings'
import { guardFromErrors, extractErrorInfo } from '../../utils/graphqlHelper'
import { errorNotification } from '../../utils/notificationHelper'

const loginQuery = `
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      refreshToken
      email
      firstName
      lastName
      roles
    }
  }
`

const meQuery = `
  query {
    me {
      token
      refreshToken
      email
      firstName
      lastName
      roles
    }
  }
`

export const login = ({ email, password, history }) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_STATE',
      active: true,
    })
    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: loginQuery,
        variables: { email, password },
      }
    )
    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { login } = data
    if (!login) {
      throw new Error("Can't login!")
    }

    const { token, refreshToken, roles } = login
    localStorage.setItem(
      'tokenStorage',
      JSON.stringify({ token, refreshToken, roles })
    )
    dispatch({
      type: types.SET_AUTH_USER,
      data: login,
    })

    dispatch({
      type: 'SET_LOADING_STATE',
      active: false,
    })
    history.push('/')
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

export const getMe = ({ token, refreshToken }) => async (dispatch) => {
  try {
    const { data, errors } = await axios.post(
      `${settings.backendUrl}/graphql`,
      {
        query: meQuery,
      }
    )
    guardFromErrors(errors)

    if (!data) {
      throw new Error('Response body is empty!')
    }

    const { me } = data
    if (!me) {
      throw new Error('Unauthorized!')
    }

    dispatch({
      type: types.SET_AUTH_USER,
      data: { ...me, token, refreshToken },
    })
  } catch (errors) {
    const errorMessage = extractErrorInfo(errors)
    dispatch(errorNotification(errorMessage))
  }
}

export const logOut = (history) => async (dispatch) => {
  localStorage.removeItem('tokenStorage')
  dispatch({ type: 'LOG_OUT' })
  history.push('/')
}
