import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Main } from '../../../components'
import { AddNewRule } from '../components'
import { Button, Popconfirm, Table, Modal } from 'antd'
import * as actions from '../actions'
import * as actionsGroups from '../../Corporations/actions'
import UpdateRule from '../components/UpdateRule'

const RulesListPage = (props) => {
  const [visible, setVisible] = useState(false)
  const [visibleRuleInfo, setVisibleRuleInfo] = useState(false)
  const [ruleIngoRecord, setRuleInfoReocord] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [existGroupName, setExistGroupName] = useState('')

  useEffect(() => {
    props.groups.map((group) => {
      if (group.id == props.location.pathname.slice(15, 19)) {
        setExistGroupName(group.name)
      }
    })
    props.setRulesList({
      page: 1,
      size: 100,
      filters: { groupId: +props.location.pathname.slice(15, 19) },
    })
  }, [])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = async (sum) => {
    setVisible(false)
    setVisibleRuleInfo(false)
  }

  const doConfirm = async (ruleId, e) => {
    props.deleteRule({ ruleId })
    e && e.stopPropagation()
  }

  const showRuleInfo = (record) => {
    setVisibleRuleInfo(true)
    setRuleInfoReocord(record)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Products',
      dataIndex: 'ruleProducts',
      key: 'ruleProducts',
      render: (record) => {
        let productsIds = []
        for (let i = 0; i < record.length; i++) {
          productsIds.push(` \n${record[i].exProductId} - ${record[i].name}`)
        }
        return (
          <div>
            {productsIds.map((item, key) => (
              <div key={key}>{item}</div>
            ))}
          </div>
        )
      },
    },
    {
      title: 'Processors',
      dataIndex: 'ruleProcessors',
      key: 'ruleProcessors',
      render: (record) => {
        let processorsIds = []
        for (let i = 0; i < record.length; i++) {
          processorsIds.push(` ${record[i].exProcessorId} - ${record[i].name}`)
        }
        return (
          <div>
            {processorsIds.map((item, key) => (
              <div key={key}>{item}</div>
            ))}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: (record) => (
        <>
          <i
            className="zmdi zmdi-search zmdi-hc-2x users-list-action_item"
            color="blue"
            title="show more"
            type="primary"
            onClick={() => showRuleInfo(record)}
          />
          <Popconfirm
            title="Are you sure delete this Rule?"
            onConfirm={(e) => doConfirm(record.id, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText="Yes"
            cancelText="No"
          >
            <i
              className="zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item"
              href="/#"
              title="Delete"
            ></i>
          </Popconfirm>
        </>
      ),
    },
  ]

  const changePaginataion = (data) => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }

  return (
    <Main>
      <h4 className="txt-dart">
        Rules for {existGroupName}({props.location.pathname.slice(15, 19)})
      </h4>
      <Button
        type="primary"
        onClick={showModal}
        className="add-new-site_button"
      >
        Add Rule
      </Button>
      <Modal
        className="customer_modal"
        title={`new rule for ${existGroupName}(${props.location.pathname.slice(
          15,
          19
        )})`}
        visible={visible}
        footer={null}
        onCancel={handleCancel}
        width="70%"
      >
        <AddNewRule
          onCancel={handleCancel}
          groupId={props.location.pathname.slice(15, 19)}
          campaignId={props.location.campaignId}
          date={new Date()}
        />
      </Modal>

      <Modal
        className="customer_modal"
        title={`update rule for ${existGroupName}(${props.location.pathname.slice(
          15,
          19
        )})`}
        visible={visibleRuleInfo}
        footer={null}
        onCancel={handleCancel}
        width="70%"
      >
        <UpdateRule onCancel={handleCancel} record={ruleIngoRecord} />
      </Modal>

      <Table
        columns={columns}
        dataSource={props.rules}
        bordered
        rowKey={(record) => record.id}
        onChange={changePaginataion}
        pagination={{
          total: props.total,
          showSizeChanger: true,
          pageSize,
          current: pageNumber,
        }}
      />
    </Main>
  )
}

const mapStateToPtops = (store) => ({
  rules: store.rules.list,
  compaigns: store.companies.list,
  compaignId: store.companies.compaignId,
  groupIdForRule: store.rules.groupId,
  groups: store.groups.list,
})

const mapDispatchToPtops = {
  setRulesList: actions.setRulesList,
  deleteRule: actions.deleteRule,
  setGroupList: actionsGroups.setGroupList,
  setRuleProcessorsList: actions.setRuleProcessorsList,
  setRuleProductsList: actions.setRuleProductsList,
  setGroupIdToRule: actions.setGroupIdToRule,
}

export default connect(mapStateToPtops, mapDispatchToPtops)(RulesListPage)
