/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Select,
  Form,
  Button,
  Space,
  InputNumber,
  Checkbox,
  Row,
  Col,
  Input,
} from 'antd'
import * as sitesActions from '../../Sites/actions'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import * as productsActions from '../../Products/actions'
import * as processorsActions from '../../Processors/actions'
import * as actions from '../actions'
const { Search } = Input

const AddNewRule = (props) => {
  const [form] = Form.useForm()
  const [errorSumMessage, setErrorSumMessage] = useState('')
  const [selectRuleProducts, setSelectRuleProducts] = useState([])
  const [allProducts, setAllProducts] = useState(false)
  const [search, setSearch] = useState(false)
  const [searchProduct, setSearchProduct] = useState([])

  useEffect(() => {
    props.fetchProcessorsList({
      page: 1,
      size: 100,
      filters: { campaignId: +props.campaignId },
    })
    props.setProductsList({
      page: 1,
      size: 1000,
      action: 'products_for_new_rule',
      filters: { campaignId: +props.campaignId },
    })
  }, [])

  useEffect(() => {
    setAllProducts(false)
    let selectedRuleProducts = []
    for (let i = 0; i < props.rules.length; i++) {
      props.rules[i].ruleProducts.map((rP) => {
        selectedRuleProducts.push(rP.exProductId)
      })
    }
    setSelectRuleProducts(selectedRuleProducts)
    setAllProducts(true)
  }, [props.date])

  useEffect(() => {
    setSearchProduct(searchProduct)
  }, [searchProduct.length])

  const onFinishHandler = async (value) => {
    let sum = 0
    let sameProcessorsArray = []
    value.processors.map((proc) => {
      sum += proc.percent
      sameProcessorsArray.push(proc.externalId)
    })
    sameProcessorsArray = sameProcessorsArray.filter(function (elem, pos, arr) {
      return pos !== arr.indexOf(elem) || pos !== arr.lastIndexOf(elem)
    })
    if (sum !== 100) {
      setErrorSumMessage('sum must be 100%')
    } else if (sameProcessorsArray.length > 0) {
      setErrorSumMessage('processors must be different!')
    } else {
      const createdRule = await props.addRule(
        +props.groupId,
        value.externalProductId,
        value.processors
      )
      const resultSendRule = await props.sendRule(createdRule, props.groupId)
      if (resultSendRule) {
        await props.setRulesList({
          page: 1,
          size: 100,
          filters: { groupId: +props.groupId },
        })
        props.setRuleProcessorsList({ page: 1, size: 100, filters: {} })
        setAllProducts(false)
        props.setRuleProductsList({ page: 1, size: 100, filters: {} })
        setErrorSumMessage('')
        form.resetFields()
        props.onCancel()
      } else {
        setErrorSumMessage("can't create rule!")
        props.deleteRule({ ruleId: +createdRule, action: 'delete_from_rule' })
      }
    }
  }

  const onSearch = (value) => {
    props.products.map((product) => {
      if (product.externalId === value) {
        searchProduct.push({
          id: product.externalId,
          name: product.name,
        })
        setSearch(true)
      }
    })
    if (value === '') {
      setSearch(false)
    }
  }

  return (
    <div className="add-processor_form-page">
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinishHandler}
        form={form}
        className="addRule_form"
      >
        <div className="rule-form-items">
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            style={{ width: 200 }}
          />
          {allProducts && !search ? (
            <Form.Item
              name="externalProductId"
              label="Products"
              rules={[{ required: true, message: 'Select product' }]}
            >
              <Checkbox.Group>
                <Row>
                  {props.products.map((product, key) => {
                    let num = selectRuleProducts.find(
                      (el) => +el === +product.externalId
                    )
                    if (!num) {
                      return (
                        <Col span={20} key={key}>
                          <Checkbox
                            value={product.externalId}
                            style={{
                              lineHeight: '32px',
                            }}
                          >
                            {product.externalId} ({product.name})
                          </Checkbox>
                        </Col>
                      )
                    }
                  })}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          ) : (
            <Form.Item
              name="externalProductId"
              label="Products"
              rules={[{ required: true, message: 'Select product' }]}
            >
              <Checkbox.Group>
                <Row>
                  <Col span={20}>
                    {searchProduct.map((product) => (
                      <Checkbox
                        value={product.id}
                        style={{
                          lineHeight: '32px',
                        }}
                      >
                        {product.id} ({product.name})
                      </Checkbox>
                    ))}
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          )}
        </div>

        <Form.List name="processors">
          {(fields, { add, remove }) => {
            return (
              <div className="rule-form-items">
                {fields.map((field, key) => {
                  return (
                    <Space
                      key={key}
                      style={{ display: 'flex', marginBottom: 8 }}
                      align="start"
                      className="task_cycle-form"
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, 'externalId']}
                        label="processors"
                        fieldKey={[field.fieldKey, 'externalId']}
                        rules={[
                          {
                            required: true,
                            message: 'Please select processor!',
                          },
                        ]}
                        className="select-form_item"
                      >
                        <Select
                          placeholder="Please select processors!"
                          style={{ width: 300 }}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toString().indexOf(input) >= 0
                          }
                        >
                          {props.processors.map((proc) => {
                            return (
                              <Select.Option
                                key={proc.id}
                                value={proc.externalId}
                              >
                                {proc.externalId} ({proc.name})
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, 'percent']}
                        fieldKey={[field.fieldKey, 'percent']}
                        rules={[
                          {
                            required: true,
                            message: 'max 100%',
                            min: 1,
                            max: 100,
                            type: 'number',
                          },
                        ]}
                        label="Percentage"
                        className="ask_modal-item__small"
                      >
                        <InputNumber style={{ width: 50 }} />
                      </Form.Item>

                      <MinusCircleOutlined
                        onClick={() => {
                          remove(field.name)
                        }}
                      />
                    </Space>
                  )
                })}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add()
                    }}
                    block
                  >
                    <PlusOutlined /> Add processor
                  </Button>
                </Form.Item>
              </div>
            )
          }}
        </Form.List>

        <div className="rule-fomr_error-message">{errorSumMessage}</div>

        <div className="add-new-processor_buttons button_position_fixed">
          <Button
            onClick={() => {
              form.resetFields()
              props.onCancel()
            }}
            className="addClient_form-button"
            type="primary"
            style={{
              background: '#1890ff',
              color: '#fff',
              borderRadius: '5px',
              padding: '5px',
              height: '40px',
              width: '100px',
              fontWeight: 'bold',
              marginRight: '5px',
            }}
          >
            <a>
              <i className="zmdi zmdi-arrow-left mr-10"></i>
              Back
            </a>
          </Button>
          <Button
            className="addClient_form-button"
            type="submit"
            style={{
              background: 'green',
              color: '#fff',
              borderRadius: '5px',
              padding: '5px',
              height: '40px',
              width: '100px',
              fontWeight: 'bold',
              marginRight: '5px',
            }}
            htmlType="submit"
          >
            <div>
              <i className="zmdi zmdi-plus mr-10" />
              Create
            </div>
          </Button>
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = (store) => ({
  sites: store.sites.list,
  processors: store.processors.list,
  rules: store.rules.list,
  ruleProducts: store.rules.ruleProductslist,
  ruleProcessors: store.rules.ruleProcessorslist,
  products: store.products.list,
  groupIdForRule: store.rules.groupId,
})

const mapDispatchToPtops = {
  fetchSitesList: sitesActions.fetchSitesList,
  fetchProcessorsList: processorsActions.fetchProcessorsList,
  setProductsList: productsActions.setProductsList,
  setRuleProcessorsList: actions.setRuleProcessorsList,
  setRulesList: actions.setRulesList,
  addRule: actions.addRule,
  sendRule: actions.sendRule,
  setRuleProductsList: actions.setRuleProductsList,
  deleteRule: actions.deleteRule,
}

export default connect(mapStateToProps, mapDispatchToPtops)(AddNewRule)
