import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form } from 'antd'
import * as actions from '../actions'

const CreateCompany = ({ createCompany, callback }) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const onFinishHandler = async (values) => {
    await createCompany(values)
    callback()
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <Button
        type="primary"
        className="add-new-site_button"
        onClick={() => setVisible(true)}
      >
        + Create Company
      </Button>
      <Drawer
        title="Create Company"
        width="400"
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please input company name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="guid"
            label="Guid"
            rules={[{ required: true, message: 'Please input company guid!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="paayKey" label="Paay Key" rules={[{ max: 200 }]}>
            <Input />
          </Form.Item>

          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button
              type="primary"
              htmlType="submit"
              className="add-new-site_button"
            >
              Create
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapDispatchToProps = {
  createCompany: actions.createCompany,
}

export default connect(null, mapDispatchToProps)(CreateCompany)
