import React, { useState, useEffect } from 'react'
import { Button, Drawer, Select, Form } from 'antd'
import CSVReader from 'react-csv-reader'
import * as actions from '../actions'
import * as companyActions from '../../Companies/actions'
import { connect } from 'react-redux'

const AddCsv = ({
  sendSvgData,
  campaigns,
  fetchCompaniesList,
  fetchCustomersList,
  siteForCustomer,
  taskName,
}) => {
  const [isVisible, setVisible] = useState(false)
  const [customData, setCustomData] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    fetchCompaniesList({ page: 1, size: 100 })
  }, [])

  const onFinishHandler = async (value) => {
    form.resetFields()
    await sendSvgData({
      data: customData,
      campaignId: +value.campaignId,
      taskName,
    })
    if (siteForCustomer) {
      fetchCustomersList({
        page: 1,
        size: 100,
        filters: { externalSiteId: +siteForCustomer },
      })
    } else {
      fetchCustomersList({
        page: 1,
        size: 100,
        filters: { campaignId: +value.campaignId },
      })
    }
    setVisible(false)
  }

  const svgData = (data) => {
    setCustomData(data)
  }

  return (
    <>
      <Button
        type="primary"
        style={{
          background: 'green',
          color: '#fff',
          borderRadius: '5px',
          padding: '5px',
          height: '40px',
          width: '300px',
          fontWeight: 'bold',
          marginRight: '5px',
        }}
        onClick={() => setVisible(true)}
      >
        + Upload customers from CSV file
      </Button>
      <Drawer
        title="Add New Customer"
        width="400"
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
        closable
      >
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={onFinishHandler}
          form={form}
        >
          <Form.Item
            name="campaignId"
            label="Select Company"
            rules={[{ required: true, message: 'Please select company!' }]}
          >
            <Select>
              {campaigns.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <CSVReader
            label="Select CSV file to download custumers"
            inputStyle={{ color: '#4f2a1b' }}
            onFileLoaded={(data) => svgData(data)}
          />

          <Button htmlType="submit" type="submit" style={{ marginTop: '20px' }}>
            Submit
          </Button>
        </Form>
      </Drawer>
    </>
  )
}

const mapStateToProps = (store) => ({
  campaigns: store.companies.list,
})

const mapDispatchToProps = {
  sendSvgData: actions.sendSvgData,
  fetchCustomersList: actions.fetchCustomersList,
  fetchCompaniesList: companyActions.fetchCompaniesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCsv)
