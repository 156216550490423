import React from 'react'
import { connect } from 'react-redux'

const Loader = ({ active }) => active
  ? (
    <div className="loader">
        <i className="fa-li fa fa-spinner fa-pulse"></i>
    </div>
  )
  : null

const mapStateToProps = store => ({
  active: store.loading.active
})

export default connect(mapStateToProps, null)(Loader)
