import * as axios from 'axios'
import { createBrowserHistory } from 'history'
import settings from '../settings'
import throttle from './throttle'

const instance = axios.create({ baseURL: settings.backendUrl })

const REFRESH_TOKEN = `
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`

const updateToken = throttle(async (res) => {
  try {
    const tokenStorage = JSON.parse(localStorage.getItem('tokenStorage'))
    if (!tokenStorage) {
      throw new Error('Refresh token is not defined')
    }
    const { refreshToken } = tokenStorage

    if (!refreshToken) {
      throw new Error('Refresh token is not defined!')
    }

    const response = await axios.post(`${settings.backendUrl}/graphql`, {
      query: REFRESH_TOKEN,
      variables: {
        refreshToken,
      },
    })

    const { data, errors } = response.data

    if (errors) {
      const message = errors.map((item) => item.message).join('\n')
      throw new Error(message)
    }

    if (!data) {
      throw new Error('Refresh token update respond with empty body!')
    }

    const newData = data?.refreshToken
    if (!newData) {
      throw new Error('Refresh token data is empty!')
    }
    const { token: newToken, refreshToken: newRefreshToken } = newData

    if (!newToken || !newRefreshToken) {
      throw new Error('New token or refreshToken was not defined in response!')
    }

    localStorage.setItem(
      'tokenStorage',
      JSON.stringify({ token: newToken, refreshToken: newRefreshToken })
    )

    res.config.headers.Authorization = `Bearer ${newToken}`
    return instance(res.config)
  } catch (exception) {
    localStorage.removeItem('tokenStorage')
    createBrowserHistory().push('login')
  }
}, 10000)
export const initializeAxiosInterceptor = () => {
  instance.interceptors.request.use(
    (config) => {
      const tokenStorage = JSON.parse(localStorage.getItem('tokenStorage'))
      const token = tokenStorage ? tokenStorage.token : ''
      config.headers.Authorization = token
        ? `Bearer ${token}`
        : config.headers.Authorization
      return config
    },
    (err) => Promise.reject(err)
  )

  instance.interceptors.response.use(
    (res) => {
      if (
        res.data.errors &&
        res.data.errors.find((error) => error.message === 'Unauthorized!')
      ) {
        return updateToken(res)
      }
      return res.data
    },
    (err) => {
      if (err.status !== 401) {
        console.log('Err', err)
        return Promise.reject(err)
      } else {
        console.log('Response error')
        return updateToken(err.response)
      }
    }
  )
}

initializeAxiosInterceptor()

export default instance
