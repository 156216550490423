import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Table, Popconfirm } from 'antd'
import { CreateCompany, UpdateCompany } from '../components'
import * as actions from '../actions'
import * as actionsUsers from '../../Clients/actions'
import { Main } from '../../../components'
import { AddUserToCompany } from '../components'

const size = 10

const CompaniesList = ({
  campaignsList,
  total,
  fetchCompaniesList,
  removeCompany,
  userId,
  getClientsList,
  companiesUsers,
  fetchCompaniesUsersList,
  users,
  login,
}) => {
  const [pageNo, setPageNo] = useState(1)
  const [allUsers, setAllUsers] = useState([])
  const [campaignUsers, setCampaignUsers] = useState([])
  const clientId = +userId

  useEffect(() => {
    if (clientId) {
      fetchCompaniesList({ page: pageNo, size, filters: { userId: clientId } })
    } else {
      fetchCompaniesList({ page: pageNo, size })
      fetchCompaniesUsersList({
        page: 1,
        size: 10,
        filters: {},
      })
    }
    getClientsList({ page: 1, size: 100, filters: {} })
  }, [clientId, pageNo])

  useEffect(() => {
    setAllUsers(users)
    setCampaignUsers(companiesUsers)
  }, [users, companiesUsers])

  useEffect(() => {
    setCampaignUsers(companiesUsers)
  }, [companiesUsers])

  const doConfirm = async (campaignId, e) => {
    e && e.stopPropagation()
    removeCompany(campaignId)
  }

  let columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'User',
      dataIndex: 'userId',
      key: 'userId',
      render: (record) => {
        const existUser = allUsers.find((el) => el.id === record)
        let adminRole
        if (login.find((item) => item === 'admin')) {
          adminRole = true
        }
        if (existUser && adminRole) {
          return (
            <>
              {existUser.firstName} ({record})
            </>
          )
        } else if (adminRole) {
          return <>admin ID - ({record})</>
        }
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record) => {
        let addUsers = () => {}
        if (login.find((item) => item === 'admin')) {
          addUsers = () => (
            <AddUserToCompany
              users={allUsers}
              campaign={record}
              userCampaigns={campaignUsers}
            />
          )
        }

        return (
          <div style={{ display: 'flex' }}>
            <UpdateCompany record={record} pageNo={pageNo} size={size} />
            <Popconfirm
              title={() => {
                let existUsersCampaign = []
                if (record) {
                  for (let i = 0; i < companiesUsers.length; i++) {
                    if (companiesUsers[i].campaignId == record.id) {
                      existUsersCampaign.push(companiesUsers[i].userId)
                    }
                  }
                  return `delete campaign for users ids - ${existUsersCampaign}`
                }
              }}
              onConfirm={(e) => doConfirm(record.id, e)}
              onCancel={(e) => e?.stopPropagation()}
              okText="Yes"
              cancelText="No"
            >
              <i
                className="zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item"
                href="/#"
                title="Delete"
              ></i>
            </Popconfirm>
            {addUsers()}
          </div>
        )
      },
    },
  ]

  const paginationHandler = (page) => {
    fetchCompaniesList({ page, size })
    setPageNo(page)
  }

  return (
    <Main>
      <h4 className="txt-dark">Companies</h4>
      <CreateCompany
        callback={() => fetchCompaniesList({ page: pageNo, size })}
      />
      <Table
        columns={columns}
        dataSource={campaignsList}
        bordered
        rowKey={(record) => record.id}
        onRow={() => {}}
        pagination={{
          onChange: paginationHandler,
          current: pageNo,
          total,
          size,
        }}
      />
    </Main>
  )
}

const mapStateToProps = (store) => ({
  campaignsList: store.companies.list,
  companiesUsers: store.companies.companiesUsers,
  total: store.companies.total,
  userId: store.companies.userId,
  users: store.clients.list,
  login: store.login.roles,
})

const mapDispatchToProps = {
  fetchCompaniesList: actions.fetchCompaniesList,
  removeCompany: actions.removeCompany,
  getClientsList: actionsUsers.getClientsList,
  updateCompany: actions.updateCompany,
  fetchCompaniesUsersList: actions.fetchCompaniesUsersList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesList)
