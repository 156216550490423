import { types } from './types'

const initState = {
  list: [],
  sitesForOptions: [],
  total: 0,
  campaignId: ''
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_SITES_LIST: {
      const { data } = action
      return {
        ...state,
        list: data.items,
        total: data.total
      }
    }
    case types.SET_SITES_FOR_OPTIONS: {
      const { data } = action
      return {
        ...state,
        sitesForOptions: data
      }
    }
    case types.REMOVE_SITE: {
      state.list = state.list.filter(item => item.id !== action.data)
      state.total -= 1
      return {
        ...state
      }
    }
    case 'LOG_OUT': {
      return { ...initState }
    }
    default: {
      return state
    }
  }
}

export default reducer
