import React, { useEffect, useState } from 'react'
import { Table, Popconfirm } from 'antd'
import { Main } from '../../../components'
import * as actions from '../actions'
import { connect } from 'react-redux'
import { DownloadProduct, UpdateProduct } from '../components'
import UpdateProducts from '../components/UpdateProducts'

const ProductsList = ({
  setProductsList,
  total,
  productsList,
  removeProduct,
  compaignId,
  compaigns,
}) => {
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    const campaignItem = JSON.parse(window.localStorage.getItem('campaign'))
    if (campaignItem !== '' && campaignItem) {
      setProductsList({
        page: pageNumber,
        size: pageSize,
        filters: { campaignId: campaignItem.id },
      })
    } else {
      setProductsList({
        page: pageNumber,
        size: pageSize,
        filters: {},
      })
    }
  }, [pageSize, pageNumber, compaignId])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'externalId',
      key: 'externalId',
    },
    {
      title: 'SiteID',
      dataIndex: 'externalSiteId',
      key: 'externalSiteId',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Compaign Name',
      dataIndex: 'campaignId',
      key: 'campaignId',
      render: (record) => {
        let name = ''
        // eslint-disable-next-line array-callback-return
        compaigns.map((item) => {
          if (+record === +item.id) {
            name = item.name
          }
        })
        return (
          <div>
            {name}({record})
          </div>
        )
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: (record) => (
        <>
          <UpdateProduct record={record} />

          <Popconfirm
            title="Are you sure delete this Product?"
            onConfirm={(e) => doConfirm(record.externalId, e)}
            onCancel={(e) => e?.stopPropagation()}
            okText="Yes"
            cancelText="No"
          >
            <i
              className="zmdi zmdi-delete zmdi-hc-2x txt-danger users-list-action_item"
              href="/#"
              title="Delete"
            ></i>
          </Popconfirm>
        </>
      ),
    },
  ]

  const doConfirm = (productId, e) => {
    e && e.stopPropagation()
    removeProduct(productId)
    if (compaignId !== '') {
      setProductsList({
        page: pageNumber,
        size: pageSize,
        filters: { campaignId: +compaignId },
      })
    } else {
      setProductsList({ page: pageNumber, size: pageSize, filters: {} })
    }
  }

  const changePaginataion = (data) => {
    const { current, pageSize: paginationSize } = data
    setPageSize(paginationSize)
    setPageNumber(current)
  }

  return (
    <Main>
      <h4 className="txt-dart">Products</h4>
      <DownloadProduct
        callback={() =>
          setProductsList({ page: pageNumber, size: pageSize, filters: {} })
        }
      />
      <UpdateProducts />
      <Table
        columns={columns}
        dataSource={productsList}
        bordered
        onChange={changePaginataion}
        rowKey={(record) => record.id}
        pagination={{
          showSizeChanger: true,
          pageSize,
          current: pageNumber,
          total,
        }}
      />
    </Main>
  )
}

const mapStateToProps = (store) => ({
  productsList: store.products.list,
  total: store.products.total,
  compaigns: store.companies.list,
  compaignId: store.companies.compaign,
})

const mapDispatchToProps = {
  setProductsList: actions.setProductsList,
  removeProduct: actions.removeProduct,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList)
