import { types } from './types'

const initState = {
  email: '',
  firstName: '',
  lastName: '',
  token: null,
  refreshToken: null,
  roles: []
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_AUTH_USER: {
      const { data } = action
      return {
        ...state,
        ...data
      }
    }
    case 'LOG_OUT': {
      return { ...initState }
    }
    default: {
      return state
    }
  }
}

export default reducer
