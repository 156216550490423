import { types } from './types'

const initState = {
  list: [],
  customer: {},
  csvCustomers: [],
  total: 0,
  totalCsvCustomers: 0
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_CUSTUMERS_LIST: {
      const { data } = action
      return {
        ...state,
        list: data.items,
        total: data.total
      }
    }
    case types.SET_CSV_CUSTUMERS_LIST: {
      const { data } = action
      const csvLength = data.length
      return {
        ...state,
        csvCustomers: data,
        totalCsvCustomers: csvLength
      }
    }
    case types.REMOVE_CSV_CUSTUMERS_LIST: {
      return {
        ...state,
        csvCustomers: []
      }
    }
    case types.REMOVE_CUSTOMER: {
      state.list = state.list.filter(item => item.id !== action.data)
      state.total -= 1
      return {
        ...state
      }
    }
    case types.SET_CUSTOMER: {
      const { data } = action
      return {
        ...state,
        customer: { ...data }
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
