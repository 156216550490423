import React, { useEffect } from 'react'
import 'antd/dist/antd.css'
import { Select } from 'antd'
import { connect } from 'react-redux'
import * as actions from '../actions'
import * as actionsSites from '../../Sites/actions'

const SiteFilter = ({
  sitesList,
  fetchCustomersList,
  total,
  fetchSitesList,
}) => {
  useEffect(() => {
    fetchSitesList({ page: 1, size: 100, filters: {} })
  }, [fetchSitesList])

  const onChange = async (value) => {
    if (value === 'all') {
      await fetchCustomersList({ page: 1, size: 10 })
    }
    fetchCustomersList({
      page: 1,
      size: 10,
      filters: { externalSiteId: +value },
    })
  }

  const onSearch = (val) => {
    // console.log('search:', val)
  }

  const { Option } = Select

  return (
    <>
      <Select
        style={{ width: 200, marginLeft: 20, marginRight: 20 }}
        showSearch
        placeholder="select a site"
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value="all">All Sites</Option>
        {sitesList.map((site) => (
          <Option key={site.id} value={site.externalId}>
            {site.name}
          </Option>
        ))}
      </Select>
    </>
  )
}

const mapStateToProps = (store) => ({
  sitesList: store.sites.list,
  total: store.sites.total,
})

const mapDispatchToProps = {
  fetchCustomersList: actions.fetchCustomersList,
  fetchSitesList: actionsSites.fetchSitesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteFilter)
