import React, { useEffect, useState } from 'react'
import 'antd/dist/antd.css'
import { Select } from 'antd'
import { connect } from 'react-redux'
import * as companyActions from '../../pages/Companies/actions'

const HeaderSelectCompany = ({
  companies,
  setCompaignId,
  fetchCompaniesList,
  campaignId,
}) => {
  const [campaign, setCampaign] = useState({})

  useEffect(() => {
    fetchCompaniesList({ page: 1, size: 100, filters: {} })
  }, [])

  useEffect(() => {
    setCampaign(JSON.parse(window.localStorage.getItem('campaign')))
  }, [])

  const onChange = async (value) => {
    if (value === 'all') {
      await setCompaignId('')
    } else {
      for (let i = 0; i < companies.length; i++) {
        if (companies[i].id === value) {
          await setCompaignId({
            id: value,
            name: companies[i].name,
          })
        }
      }
    }
  }

  const onSearch = (val) => {
    // console.log(`search: ${val}`)
  }

  const { Option } = Select

  return (
    <>
      <Select
        style={{ width: 200, marginLeft: 20, marginRight: 20 }}
        showSearch
        placeholder={campaign ? campaign.name : 'All Companies'}
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value="all">All Companies</Option>
        {companies.map((company) => (
          <Option key={company.id} value={company.id}>
            {company.name}
          </Option>
        ))}
      </Select>
    </>
  )
}

const mapStateToProps = (store) => ({
  companies: store.companies.list,
  campaignId: store.companies.compaignId,
})

const mapDispatchToProps = {
  setCompaignId: companyActions.setCompaignId,
  fetchCompaniesList: companyActions.fetchCompaniesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSelectCompany)
